import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
import { PageHeader } from "../components/PageHeader";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader title="Hi! I'm Brett 👋" imgSrc="/headshot.png" elevateImg hideDivider mdxType="PageHeader" />
    <center style={{
      "padding": "0 6rem"
    }}>
  I'm a <strong>full stack engineer</strong> that loves building{" "}
  <strong>delightful</strong> and <strong>dependable</strong> products 💜
    </center>
    <br />
    <h3>{`Welcome!`}</h3>
    <p>{`I'm flattered you're here to `}<del parentName="p">{`stroke my ego`}</del>{` spend some time to learn about me.`}</p>
    <p>{`Feel free to browse the files on the left. They're a great way to learn about where I've been, what's important to me, and how I like to design and build software.`}</p>
    <p>{`My `}<a parentName="p" {...{
        "href": "/portfolio/"
      }}>{`portfolio`}</a>{` is a good place to check out some projects I've worked on outside of my job. `}<a parentName="p" {...{
        "href": "https://github.com/babramczyk"
      }}>{`My GitHub`}</a>{` profile is where you can dig deeper into some of the problems I've solved over the years.`}</p>
    <p>{`You can also check out `}<a parentName="p" {...{
        "href": "https://wiki.abramczyk.dev"
      }}>{`my wiki`}</a>{`, where I write down everything I learn and am interested in, from `}<a parentName="p" {...{
        "href": "https://wiki.abramczyk.dev/ideas/mental-models"
      }}>{`mental models`}</a>{` to `}<a parentName="p" {...{
        "href": "https://wiki.abramczyk.dev/software/shell-scripting"
      }}>{`shell scripting`}</a>{` to `}<a parentName="p" {...{
        "href": "https://wiki.abramczyk.dev/well-being/health/nutrition/intermittent-fasting"
      }}>{`intermittent fasting`}</a>{`.`}</p>
    <p>{`Slides for talks I've given are `}<a parentName="p" {...{
        "href": "https://slides.com/brettabramczyk-1"
      }}>{`available here`}</a>{` on topics ranging from `}<a parentName="p" {...{
        "href": "https://slides.com/brettabramczyk-1/deck"
      }}>{`becoming friends with your computer`}</a>{` to `}<a parentName="p" {...{
        "href": "https://slides.com/brettabramczyk-1/testing-ui-components"
      }}>{`testing UI components`}</a>{`.`}</p>
    <p>{`On `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/brett-abramczyk/"
      }}>{`my LinkedIn profile`}</a>{`, you can learn more about me at a professional level, or reach out just to chat 🙂`}</p>
    <br />
    <hr />
    <br />
    <h3>{`About Me`}</h3>
    <p><em parentName="p">{`After graduating with a CS degree from `}<a parentName="em" {...{
          "href": "/school/"
        }}>{`UW-Madison`}</a>{`, I started working at a startup called `}<a parentName="em" {...{
          "href": "/akitabox/"
        }}>{`AkitaBox`}</a>{`, where I worked my way up to managing a small agile team. After two formative years there, I moved to Milwaukee to work on the Innovation Team at `}<a parentName="em" {...{
          "href": "/northwestern-mutual/"
        }}>{`Northwestern Mutual`}</a>{`, then moved to Berlin to join `}<a parentName="em" {...{
          "href": "/wonder/"
        }}>{`Wonder`}</a>{`. Now, I'm finally back in Milwaukee, catching my breath for once and having a blast building virtual offices (and eventually, more...) at `}<a parentName="em" {...{
          "href": "/gather/"
        }}>{`Gather`}</a>{`.`}</em></p>
    <p><em parentName="p">{`I take a lot of pride in developing my range -- not just as an engineer, but as a teammate. I've been lucky to explore and develop this range professionaly; from nerding out on `}<a parentName="em" {...{
          "href": "/toolkit/"
        }}>{`tech`}</a>{` like React and AWS, to collaborating with the product team on UI/UX design, to team leading and ticket grooming, to teaching and mentorship.`}</em></p>
    <p><em parentName="p">{`I'm known to care`}</em>{` a lot `}<em parentName="p">{`about whatever I'm working on or fascinated by. In recent years, that's meant learning everything about sleep, nutrition, fitness, mental health. Lately, I'm particularly interested in understanding emotions, how they drive myself and others, and generally how people work think and behave.`}</em></p>
    <p><em parentName="p">{`I also love a good board game. Have you checked out `}<a parentName="em" {...{
          "href": "https://boardgamegeek.com/boardgame/237182/root"
        }}>{`Root`}</a>{`? Man, that game changed my life...`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      